export default () => {
  const URL = window.getURL();
  let { pathname } = URL;

  if (pathname.length > 1 && pathname.split('').pop() === '/') {
    pathname = pathname.slice(0, -1);
  }

  const nav = document.querySelector('[data-js-navigation]');

  Array.from(nav.querySelectorAll(`a[href="${pathname}"]`)).forEach((link) =>
    link.classList.add('is-active')
  );
};
