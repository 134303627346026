import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import calculateAmortisedValue from '../helpers/calculate-amortisation';
import formatCurrency from '../helpers/format-currency';

function LoanBreakdown({
  cta,
  currencyType,
  isHidden,
  labels,
  loanAmount,
  loanTerm,
  rates,
  showHide,
  termLengthYear,
}) {
  const amoritisedValues = calculateAmortisedValue(loanAmount, loanTerm, rates);

  const {
    monthlyPayments,
    interestFee,
    completionFee,
    totalCost,
  } = formatCurrencies(amoritisedValues, currencyType, 0);
  const requestedLoanAmount = formatCurrency(loanAmount, currencyType, 0);

  function formatCurrencies(currencyObj, currency, decimal) {
    return Object.keys(currencyObj).reduce(
      (accObj, currentKey) => ({
        ...accObj,
        [currentKey]: formatCurrency(
          currencyObj[currentKey],
          currency,
          decimal
        ),
      }),
      {}
    );
  }

  const showBreakDown =
    isHidden === true
      ? labels.showLoanBreakdownLabel
      : labels.hideLoanBreakdownLabel;

  return (
    <div className="calculator__results">
      <p className="calculator__monthly-payments-amount u-mb-none">
        {monthlyPayments}
        <span className="calculator__monthly-paymentsi-label">{`/${termLengthYear}`}</span>
      </p>
      <p
        className="calculator__show-hide-label u-mb-none"
        onClick={(e) => showHide(e)}
      >
        {showBreakDown}&nbsp;
        <span className={classNames({ 'is-hidden-on-mobile': !isHidden })}>
          <svg className="svg-icon svg-icon--action svg-icon--small">
            <use xlinkHref="https://dgnvhpcjbd3ba.cloudfront.net/radius/10.15.1/icons/svg-sprite.svg#chevron-down"></use>
          </svg>
        </span>
        <span className={classNames({ 'is-hidden-on-mobile': isHidden })}>
          <svg className="svg-icon svg-icon--action svg-icon--small">
            <use xlinkHref="https://dgnvhpcjbd3ba.cloudfront.net/radius/10.15.1/icons/svg-sprite.svg#chevron-up"></use>
          </svg>
        </span>
      </p>
      <ul
        className={classNames(
          { 'is-hidden-on-mobile': isHidden },
          'calculator__result-list'
        )}
      >
        <li className="u-mb-x-small calculator__result calculator__result-loan">
          <div className="calculator__result-left">
            <span className="calculator__result-dots"></span>
            {labels.loanAmountLabel}
          </div>
          <div className="calculator__result-right">{requestedLoanAmount}</div>
        </li>
        <li className="u-mb-x-small calculator__result calculator__result-origination">
          <div className="calculator__result-left">
            <span className="calculator__result-dots"></span>
            {labels.originationFeeLabel}
          </div>
          <div className="calculator__result-right">{completionFee}</div>
        </li>
        <li className="u-mb-x-small calculator__result calculator__result-interest">
          <div className="calculator__result-left">
            <span className="calculator__result-dots"></span>
            {labels.interestLabel}
          </div>
          <div className="calculator__result-right">{interestFee}</div>
        </li>
        <li className="u-mb-x-small calculator__result calculator__result-total u-mb-large">
          <div className="calculator__result-left">
            <strong>{labels.totalCostLabel}</strong>
          </div>
          <div className="calculator__result-right">
            <strong>{totalCost}</strong>
          </div>
        </li>
      </ul>
      <div className="cta-combo calculator__button--desktop">
        <a
          className={`cta-primary cta-combo__primary ${cta.primaryCustomClass}`}
          href={cta.primaryCtaLink}
        >
          {cta.primaryCtaLabel}
        </a>
        <span className="cta-combo__secondary-wrap">
          {cta.connectorLabel}
          <a
            className={`cta-link cta-combo__secondary ${cta.secondaryCustomClass}`}
            href={cta.secondaryCtaLink}
          >
            {cta.secondaryCtaLabel}
          </a>
        </span>
      </div>
    </div>
  );
}

LoanBreakdown.propTypes = {
  cta: PropTypes.shape({
    primaryCtaLabel: PropTypes.string.isRequired,
    primaryCtaLink: PropTypes.string.isRequired,
    primaryCustomClass: PropTypes.string.isRequired,
    connectorLabel: PropTypes.string.isRequired,
    secondaryCtaLabel: PropTypes.string.isRequired,
    secondaryCtaLink: PropTypes.string.isRequired,
    secondaryCustomClass: PropTypes.string.isRequired,
  }).isRequired,
  currencyType: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    loanAmountLabel: PropTypes.string.isrequired,
    originationFeeLabel: PropTypes.string.isrequired,
    interestLabel: PropTypes.string.isrequired,
    totalCostLabel: PropTypes.string.isrequired,
    showLoanBreakdownLabel: PropTypes.string.isrequired,
    hideLoanBreakdownLabel: PropTypes.string.isrequired,
  }).isRequired,
  loanAmount: PropTypes.number.isRequired,
  loanTerm: PropTypes.number.isRequired,
  rates: PropTypes.objectOf(
    PropTypes.shape({
      interestRate: PropTypes.number.isRequired,
      completionRate: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  showHide: PropTypes.func.isRequired,
  termLengthYear: PropTypes.string.isRequired,
};

export default LoanBreakdown;
