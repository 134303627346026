/**
 * section: M2DM/components/calculator
 * title: Calculator
 *
 * activateComponent: radius-component-calculator
 * jsModule: calculator
 * warningContent: The formulae used by this component are only suitable for UK. It should not be used for other geos before updating.
 *
 * requires: radius-component-form-field-status
 *
 * purpose: Use to serve additional content in an overlay above all other content.
 *
 * description: |
 *
 *   Only one calculator can be displayed per page<br><br>
 *
 *   The calculator should be given the full width of a section and not placed adjacent to any other elemnts on the page<br><br>
 *
 *   The Calculator should be inside of the u-page-grid utility class but not any other spacing util classes or css<br><br>
 *
 *
 *   ### How to use
 *   1. Place a block html element on the page with the data attribute data-js-radius-calculator in the location you want your calculator to be rendered
 *   2. Import radius styles with `@include radius-component-cta;`
 *   3. Import javascript with `import calculator from 'radius/dist/m2dm/js/calculator';`
 *   4. Call calculator function (`calculator({...});`) - passing all customizations as a single object (see below for config details)<br><br>
 *
 *   ### How it works
 *   This component is built in react, but in order for this to be able to be used in static content you don't use the component like a normal react component. The javascript looks for the `data-js-radius-calculator` attribute and then renders the calculator inside of the div.
 *   <br>
 *   <br>
 *   All the text on the calculator is completely customizable through a single configuration object, passed as the sole parameter to the calculator method. The calculator_blurb is the only key that can take html and output it correctly.
 *   <br>
 *   <br>
 *   If you forget to pass in any required props to the calculator, the default configuration object will override that property for you. Be sure to pass in necessary properties.
 *   <br>
 *   <br>
 *   To utilize between different currency symbols based on geography, simply pass in the currency symbol ($, £, €) as the currencyType key in the configuration object.
 *   <br>
 *   <br>
 *   Currently the calculator has a max of six term loans. Any more than that will break the current design of the calculator.
 *   <br>
 *   <br>
 *   Using something like the svg4everybody(https://github.com/jonathantneal/svg4everybody) js library to help with importing svgs directly from Radius will help with any 403 errors you may be getting
 *   <br>
 *   <br>
 *   ### Configuration
 *   Call calculator method with all customizations pased as single object (see below for config) `calculator({...});`
 *
 *   ```
 *   {
 *     calculatorBlurb: 'An HTML safe string that will be displayed on the bottom left hand side of the calculator. Valid html is permitted.',
 *     cta: {
 *       primaryCtaLink: '/us/apply/',
 *       primaryCtaLabel: 'Find your rate',
 *       primaryCustomClass: 'a-class-to-be-added-to-primary-cta can-be-used-for-gtm-tracking can-add-as-many-classes-as-you-like',
 *       connectorLabel: 'or',
 *       secondaryCtaLink: '/us/apply/',
 *       secondaryCtaLabel: 'example Text',
 *       secondaryCustomClass: 'a-class-to-be-added-to-secondary-cta can-be-used-for-gtm-tracking can-add-as-many-classes-as-you-like',
 *     },
 *     currencyType: '$',
 *     loanAmount: 150000,
 *     loanAmountLabel: 'How much do you want to borrow?',
 *     loanBreakDownLabels: {
 *       loanAmountLabel: 'Loan amount',
 *       originationFeeLabel: 'Origination fee',
 *       interestLabel: 'Interest',
 *       totalCostLabel: 'Total loan cost',
 *       showLoanBreakdownLabel: 'Show Loan breakdown',
 *       hideLoanBreakdownLabel: 'Hide Loan breakdown',
 *     },
 *     minLoanAmount: 25000,
 *     minAmountLabelWarning: 'Our loans only go as low as $25,000. Please choose a higher number',
 *     maxLoanAmount: 500000,
 *     monthlyRepaymentLabel: 'month',
 *     ratesPerTerm: {
 *       6: { interestRate: 0.0499, completionRate: 0.0349 },
 *       12: { interestRate: 0.0730, completionRate: 0.0349 },
 *       24: { interestRate: 0.0760, completionRate: 0.0349 },
 *       36: { interestRate: 0.0790, completionRate: 0.0399 },
 *       48: { interestRate: 0.0820, completionRate: 0.0499 },
 *       60: { interestRate: 0.0850, completionRate: 0.0599 },
 *     },
 *     selectedLoanTerm: 24,
 *     termLengthLabel: 'For how long?',
 *     termLengthYear: 'months',
 *    };```
 *
 *
 *   ## Dependencies
 *   The Radius Components Form Field Status, and Stylised Radio must be included in your applications stylesheet: <br>
 *   `@include radius-component-form-field-status;`<br>
 *   `@include radius-component-form-stylised-radio;`<br>
 *
 * markup: components/calculator/js/markup
 *
 */

import React from 'react';
import { render } from 'react-dom';

import Calculator from './components/calculator';
import createConfiguration from './create-configuration';
import defaults from './defaults';

export default (options) => {
  const configs = createConfiguration(options, defaults);

  if (document.querySelector('[data-js-radius-calculator]')) {
    render(
      <Calculator initialState={configs} />,
      document.querySelector('[data-js-radius-calculator]')
    );
  }
};
