export default () => {
  const ctas = Array.from(document.querySelectorAll('.cta-primary--process'));
  const CLASS_PROCESSING = 'is-processing';

  ctas.forEach((el) => {
    el.addEventListener('click', () => {
      el.classList.toggle(CLASS_PROCESSING);
    });
  });
};
