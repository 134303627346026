/**
 * section: M2DM/components/forms/javascript/currency-input
 * title: Currency input
 *
 * jsModule: currency-input
 * jsFunction: inputCurrency
 *
 * description: |
 *   The currency input component converts the digits entered by users into the input field into a currency formatted value.
 *   E.g.: the user inputs `12345.67`. The input updates the value to be `£12,345.67`
 *
 *   ### Data attributes:
 *     - `[data-js-currency]`: This is the selector which is looped through to initiate the component via Javascript.
 *     - `[data-js-currency-min]`: A numeric value that represents the minimum allowed value. If the user inputs anything below this value, the input gets the `is-invalid` class applied.
 *     - `[data-js-currency-max]`: A numeric value that represents the maximum allowed value. If the user inputs anything above this value, the input gets the `is-invalid` class applied.
 *     - `[data-js-currency-locale]`: input language. Default is "en-us". Accepted values are "en-gb", "nl-nl", "de".
 *     - `[data-js-currency-format]`: a Regex to set the currency format. Default is `$0,0[.]00`. To set a custom one, `$` represents the locale currency, `,` represents the thousands separator, `.` represents the decimal places separator. For a full list of formats, check <a href="http://numeraljs.com/#custom-formats" target="_blank" rel="nofollow">NumeralJS</a>.
 *
 * markup: components/forms/js/input-currency/example
 */

import numeral from 'numeral';

import localeGB from 'numeral/locales/en-gb'; // eslint-disable-line no-unused-vars
import localeDE from 'numeral/locales/de'; // eslint-disable-line no-unused-vars
import localeNL from 'numeral/locales/nl-nl'; // eslint-disable-line no-unused-vars

export default function inputCurrency() {
  const inputs = Array.from(document.querySelectorAll('[data-js-currency]'));

  if (!inputs) {
    return;
  }

  const CONFIG = {
    CLASS_INVALID: 'is-invalid',
    CLASS_VALID: 'is-valid',
    MAX_NUMBER: 999999999999,
    LOCALE_APPROVED: ['de', 'en-gb', 'nl-nl'],
  };

  inputs.forEach((input) => {
    const params = {
      min: parseFloat(input.dataset.jsCurrencyMin) || false,
      max: parseFloat(input.dataset.jsCurrencyMax) || false,
      locale: getLocale(input),
      format: input.dataset.jsCurrencyFormat || '$0,0[.]00',
      decimalPlace: '.',
    };

    // set locale language and update decimal place
    setLocale(params.locale, CONFIG.LOCALE_APPROVED);
    params.decimalPlace = getDecimalPlace(numeral(1.1).format('0.0'));

    input.addEventListener('input', () => {
      formatValue(input, params, CONFIG);
    });

    if (input.value) {
      formatValue(input, params, CONFIG);
    }
  });
}

function formatValue(input, params, CONFIG) {
  let value = numeral(input.value).value();
  value = value > CONFIG.MAX_NUMBER ? Math.floor(value / 10) : value;

  const caretPosition = input.value.length - input.selectionStart;

  const chars = {
    last: input.value.charAt(input.value.length - 1),
    beforeLast: input.value.charAt(input.value.length - 2),
  };

  // set validity class
  setValidity(
    value,
    params.min,
    params.max,
    input,
    CONFIG.CLASS_INVALID,
    CONFIG.CLASS_VALID
  );

  if (
    (chars.last === params.decimalPlace ||
      (chars.beforeLast === params.decimalPlace && chars.last === '0')) &&
    Number.isInteger(value)
  ) {
    return;
  }

  // format value
  input.value = numeral(value).format(params.format);

  // update caret position
  setCaretPosition(input, caretPosition, params);
}

function setCaretPosition(input, caretPosition, params) {
  if (caretPosition) {
    const currentCaretPosition = input.value.length - caretPosition;

    input.setSelectionRange(currentCaretPosition, currentCaretPosition);
  }

  if (
    input.selectionStart === input.value.length &&
    input.value.charAt(input.value.length - 3) === params.decimalPlace &&
    input.value.charAt(input.value.length - 1) === '0'
  ) {
    const currentCaretPosition = input.value.length - 1;

    input.setSelectionRange(currentCaretPosition, currentCaretPosition);
  }
}

function setValidity(value, min, max, input, CLASS_INVALID, CLASS_VALID) {
  let isValid = true;
  if (value === null || Number.isNaN(value)) {
    isValid = false;
  }

  if (min && !(value >= min)) {
    isValid = false;
  }

  if (max && !(value <= max)) {
    isValid = false;
  }

  input.classList.toggle(CLASS_VALID, isValid);
  input.classList.toggle(CLASS_INVALID, !isValid);
}

function getLocale(input) {
  if (input.dataset.jsCurrencyLocale) {
    return input.dataset.jsCurrencyLocale.toLowerCase();
  }

  const lang = document.documentElement.lang.toLowerCase();

  if (lang && lang !== 'en-us') {
    return lang;
  }

  return false;
}

function getDecimalPlace(value) {
  return value.replace(/\d/g, '');
}

function setLocale(locale, allLocals) {
  if (locale && allLocals.indexOf(locale) > -1) {
    numeral.locale(locale);
  }
}
