import ClipboardCopier from './services/clipboard-copier';

export const COPYABLE_CODE_COMPONENT_SELECTOR =
  '[data-doc-component="CopyableCode"]';

export const CopyableCode = (() => {
  const classNames = {
    CODE_CONTAINER: 'copyable-code',
    COPY_BTN: 'js-copy-code',
    CODE_ACTION_SECTION: 'copyable-code__actions',
  };

  const selectors = {
    CODE: 'code, [data-language="html"]',
  };

  const initialState = () => ({
    copied: false,
    codeCopySection: null,
  });

  return class {
    constructor(props = {}) {
      this.props = props;
      this.state = initialState();
      this.handleCopyBtnClick = this.handleCopyBtnClick.bind(this);

      this.updateCSSClass();
      this.insertCopyButton();
      this.listenToCopyButtonClick();
    }

    updateCSSClass() {
      this.props.el.classList.add(classNames.CODE_CONTAINER);
    }

    insertCopyButton() {
      const { el } = this.props;
      this.setState({
        codeCopySection: el.appendChild(this.createCopyCodeSection()),
      });
    }

    listenToCopyButtonClick() {
      const { el } = this.props;
      el.addEventListener('click', this.handleCopyBtnClick);
    }

    handleCopyBtnClick(event) {
      if (event.target.matches(`.${classNames.COPY_BTN}`)) {
        this.copyToClipboard();
        this.setState({
          copied: true,
        });
        this.reRenderCopySection(this.copyButtonHTML());
      }
    }

    createCopyCodeSection() {
      const codeAction = document.createElement('div');
      codeAction.className = classNames.CODE_ACTION_SECTION;
      codeAction.innerHTML = this.copyButtonHTML();
      return codeAction;
    }

    reRenderCopySection(html) {
      const { codeCopySection } = this.state;
      codeCopySection.innerHTML = html;
    }

    copyButtonHTML() {
      return `<button type="button" class="cta-primary cta--minor code-actions__cta ${
        classNames.COPY_BTN
      }">${!this.state.copied ? 'Copy' : 'Copied!'}</button>`;
    }

    copyToClipboard() {
      const { el } = this.props;
      const codeToCopy = el.querySelector(`${selectors.CODE}`).innerHTML;
      return ClipboardCopier(codeToCopy).copy();
    }

    setState(partialState) {
      this.state = {
        ...this.state,
        ...partialState,
      };
    }
  };
})();

export default () =>
  Array.from(document.querySelectorAll(COPYABLE_CODE_COMPONENT_SELECTOR)).map(
    (el) => new CopyableCode({ el })
  );
