import 'svgxuse';

// global helpers
import getURL from './global/get-url';

// radius components
import accordion from '../../components/accordion/js';
import calculator from '../../components/calculator/js';
import cardTicker from '../../components/card-ticker/js';
import cookieStatement from '../../components/cookie-statement/js';
import hasJs from '../../globals/js/has-js';
import header from '../../components/header/js';
import inputCurrency from '../../components/forms/js/input-currency';
import modal from '../../components/modal/js';
import notification from '../../components/notification/js';
import scrollTo from '../../components/scroll-to/js';
import stepCard from '../../components/step-card/js';
import stepCardList from '../../components/step-card/js/stepCardList';
import tabs from '../../components/tabs/js';
import toggle from '../../globals/js/toggle';
import transitionsSupported from '../../globals/js/transitions-supported';
import trustpilot from '../../components/trustpilot/js';
import videoBlock from '../../components/video-block/js';

// styleguide components
import colorSearch from './components/color-search';
import collapsibleNav from './components/collapsible-nav';
import ctaProcess from './components/cta-process';
import navigation from './components/navigation';
import quote from './components/quote';
import progress from './components/progress';
import copyableCodeBlocks from './components/copyable-code-blocks';

// global helpers
getURL();

accordion();
calculator({
  loanAmount: 150000,
  currencyType: '$',
  minLoanAmount: 25000,
  maxLoanAmount: 500000,
  ratesPerTerm: {
    6: { interestRate: 0.0499, completionRate: 0.0349 },
    12: { interestRate: 0.073, completionRate: 0.0349 },
    24: { interestRate: 0.076, completionRate: 0.0349 },
    36: { interestRate: 0.079, completionRate: 0.0399 },
    48: { interestRate: 0.082, completionRate: 0.0499 },
    60: { interestRate: 0.085, completionRate: 0.0599 },
  },
  selectedLoanTerm: 24,
});
cardTicker();
collapsibleNav();
cookieStatement();
ctaProcess();
hasJs();
header();
inputCurrency();
modal({ animationsOn: transitionsSupported });
navigation();
notification();
progress();
quote();
scrollTo();
stepCard();
stepCardList();
tabs();
toggle();
trustpilot().start();
videoBlock();
copyableCodeBlocks();
colorSearch();
