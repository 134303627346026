import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const { RADIUS_ASSET_PATH } = process.env;
const storybookAssetsPath = '/m2dm-public/assets';

const Icon = ({ hidden, iconSet, extraClasses, modifiers, name }) => {
  const generateClasses = () =>
    modifiers.map((modifier) => `svg-icon--${modifier}`);

  const classes = classNames('svg-icon', ...generateClasses(), extraClasses);

  return (
    <svg className={classes} aria-hidden={hidden} aria-label={`${name} icon`}>
      <use
        xlinkHref={`${RADIUS_ASSET_PATH ||
          storybookAssetsPath}/${iconSet}/svg-sprite.svg#${name}`}
      />
    </svg>
  );
};

Icon.defaultProps = {
  hidden: false,
  extraClasses: null,
  iconSet: 'icons',
  modifiers: [],
};

Icon.propTypes = {
  hidden: PropTypes.bool,
  iconSet: PropTypes.oneOf(['icons', 'business-icons']),
  extraClasses: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
};

export default Icon;
