/**
 * section: M2DM/components/notification/javascript
 * title: Javascript
 *
 * jsModule: notification
 *
 * description: |
 *    If you want to make the notification to collapse after a certain amount of time, add the [data-js-notification-collapse] attribute on the block element.
 *
 * markup: components/notification/example-javascript
 */

export default () => {
  const el = document.querySelector('[data-js-notification-collapse]');
  const WAIT_TIME = 10000;
  const COLLAPSED_CLASS = 'is-collapsed';

  if (!el) {
    return;
  }

  setTimeout(() => {
    window.requestAnimationFrame(() => {
      el.style.height = `${el.clientHeight}px`;

      window.requestAnimationFrame(() => {
        el.classList.add(COLLAPSED_CLASS);
      });
    });
  }, WAIT_TIME);
};
