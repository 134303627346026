export default () => {
  const colorContainer = document.querySelector(
    '[data-js-color-search-container]'
  );

  if (!colorContainer) return;

  const MATCHER_RGB = /^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)$/i;
  const MATCHER_HEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

  const colorFilter = document.querySelector('[data-js-color-search-input]');

  const allColours = colorContainer.querySelectorAll(
    '[data-js-color-search-item]'
  );

  const colorMap = [...allColours].reduce((acc, colorElement) => {
    const colorSample = colorElement.querySelector(
      '[data-js-color-search-sample]'
    );
    const color = `${getComputedStyle(colorSample).backgroundColor}`;
    acc[color] = colorElement;

    return acc;
  }, {});

  const toggleAllColors = (show) => {
    Object.values(colorMap).forEach((el) => {
      el.style.display = show ? 'flex' : 'none';
    });
  };

  const showSpecificColor = (red, green, blue) => {
    Object.entries(colorMap).forEach(([color, el]) => {
      const matches = color === `rgb(${red}, ${green}, ${blue})`;

      el.style.display = matches ? 'flex' : 'none';
    });
  };

  colorFilter.addEventListener('input', ({ target: { value } }) => {
    const colourValue = value.replace(/[^\d\w(),#]/g, '');

    if (!colourValue) return toggleAllColors(true);

    const matchRgb = colourValue.match(MATCHER_RGB);
    if (matchRgb) {
      const [, red, green, blue] = [...matchRgb];

      return showSpecificColor(red, green, blue);
    }

    const matchHex = colourValue.match(MATCHER_HEX);
    if (matchHex) {
      const red = parseInt(matchHex[1], 16);
      const green = parseInt(matchHex[2], 16);
      const blue = parseInt(matchHex[3], 16);

      return showSpecificColor(red, green, blue);
    }

    return toggleAllColors(false);
  });
};
