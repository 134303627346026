import React from 'react';
import PropTypes from 'prop-types';

import calculateAmortisedValue from '../helpers/calculate-amortisation';
import formatCurrency from '../helpers/format-currency';

function Donut({
  loanAmount,
  loanTerm,
  currencyType,
  rates,
  monthlyRepaymentLabel,
}) {
  const { interestFee, monthlyPayments, totalCost } = calculateAmortisedValue(
    loanAmount,
    loanTerm,
    rates
  );

  const payments = formatCurrency(monthlyPayments, currencyType, 0);
  const stroke = 17;
  const donutSize = 120;
  const normalizedRadius = donutSize - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const loanAmountPercentage = ((loanAmount / totalCost) * 100).toFixed(2);
  const interestPercentage = ((interestFee / totalCost) * 100).toFixed(2);
  const strokeDashoffset =
    circumference - (loanAmountPercentage / 100) * circumference;
  const interstStrokeDashoffset =
    circumference - (interestPercentage / 100) * circumference;

  const loanAmountColor = '#34b576';
  const originationFeeColor = '#70d8a6';
  const interestColor = '#b5edd2';

  return (
    <div className="calculator__donut">
      <svg
        width={donutSize * 2}
        height={donutSize * 2}
        className="calculator__donut-svg"
        viewBox={`0 0 ${donutSize * 2} ${donutSize * 2}`}
      >
        <g transform={'rotate(270) translate(-240)'}>
          <circle
            className="donut-segment"
            cx={donutSize}
            cy={donutSize}
            r={normalizedRadius}
            fill="transparent"
            stroke={originationFeeColor}
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset="0"
          ></circle>
          <circle
            className="donut-segment"
            cx={donutSize}
            cy={donutSize}
            r={normalizedRadius}
            fill="transparent"
            stroke={loanAmountColor}
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={`${strokeDashoffset}`}
          ></circle>
          <circle
            className="donut-segment"
            cx={donutSize}
            cy={donutSize}
            r={normalizedRadius}
            fill="transparent"
            stroke={interestColor}
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={`-${interstStrokeDashoffset}`}
          ></circle>
        </g>

        <g className="donut__text">
          <text
            x="50%"
            y="50%"
            className="donut__number"
            transform={'translate(0, 5)'}
          >
            {payments}
          </text>
          <text
            x="50%"
            y="50%"
            className="donut__label"
            transform={'translate(0, 32)'}
          >
            {`/${monthlyRepaymentLabel}`}
          </text>
        </g>
      </svg>
    </div>
  );
}

Donut.propTypes = {
  currencyType: PropTypes.string.isRequired,
  loanAmount: PropTypes.number.isRequired,
  loanTerm: PropTypes.number.isRequired,
  rates: PropTypes.objectOf(
    PropTypes.shape({
      interestRate: PropTypes.number.isRequired,
      completionRate: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  monthlyRepaymentLabel: PropTypes.string.isRequired,
};

export default Donut;
