export default () => {
  const SELECTOR_ITEM = '[data-js-collapsible-nav-item]';
  const SELECTOR_BUTTON = '[data-js-collapsible-nav-button]';
  const CLASS_OPEN = 'is-open';

  const nav = document.querySelector('[data-js-collapsible-nav]');

  if (!nav) {
    return;
  }

  let { pathname } = window.getURL();

  if (pathname.length > 1 && pathname.split('').pop() === '/') {
    pathname = pathname.slice(0, -1);
  }

  let activeItem = document.querySelector(
    `${SELECTOR_ITEM} a[href="${pathname}"]`
  );
  while (activeItem && activeItem !== nav) {
    if (activeItem.matches(SELECTOR_ITEM)) {
      activeItem.classList.add(CLASS_OPEN);
    }

    activeItem = activeItem.parentElement;
  }

  nav.addEventListener(
    'click',
    (e) => {
      let item = e.target.closest(SELECTOR_BUTTON);

      if (!item) {
        return;
      }

      while (!item.matches(SELECTOR_ITEM) && item.parentElement) {
        item = item.parentElement;
      }

      item.classList.toggle(CLASS_OPEN);
    },
    false
  );
};
