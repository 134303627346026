const defaults = {
  calculatorBlurb:
    'This calculation is based on rates offered to some of our most creditworthy borrowers. To see what rate and monthly repayment your business is qualified for, <a href="/us/app.y" className="cta-link">apply today</a>.',
  cta: {
    primaryCtaLink: '/us/apply',
    primaryCtaLabel: 'Find your rate',
    primaryCustomClass: 'gtm-calculator-primary-class',
    connectorLabel: 'or',
    secondaryCtaLink: '/us/apply',
    secondaryCtaLabel: 'example Text',
    secondaryCustomClass: 'gtm-calculator-secondary-class',
  },
  currencyType: '$',
  loanAmount: 150000,
  loanAmountLabel: 'How much do you want to borrow?',
  loanBreakDownLabels: {
    hideLoanBreakdownLabel: 'Hide Loan breakdown',
    interestLabel: 'Interest',
    loanAmountLabel: 'Loan amount',
    originationFeeLabel: 'Origination fee',
    showLoanBreakdownLabel: 'Show Loan breakdown',
    totalCostLabel: 'Total loan cost',
  },
  maxLoanAmount: 500000,
  minAmountLabelWarning:
    'Our loans only go as low as $25,000. Please choose a higher number',
  minLoanAmount: 25000,
  monthlyRepaymentLabel: 'month',
  ratesPerTerm: {
    6: { interestRate: 0.0499, completionRate: 0.0349 },
    12: { interestRate: 0.073, completionRate: 0.0349 },
    24: { interestRate: 0.076, completionRate: 0.0349 },
    36: { interestRate: 0.079, completionRate: 0.0399 },
    48: { interestRate: 0.082, completionRate: 0.0499 },
    60: { interestRate: 0.085, completionRate: 0.0599 },
  },
  selectedLoanTerm: 24,
  termLengthLabel: 'For how long?',
  termLengthYear: 'months',
};

export default defaults;
