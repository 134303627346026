import React from 'react';
import PropTypes from 'prop-types';

import formatCurrency from '../helpers/format-currency';

function NumericRange({
  currencyType,
  handleChange,
  loanAmount,
  maxLoanAmount,
  minLoanAmount,
}) {
  return (
    <div className="calculator__range-container">
      <input
        type="range"
        name="loan-amount-range"
        id="loan-amount-range"
        className="calculator__range-input"
        min={minLoanAmount}
        max={maxLoanAmount}
        value={loanAmount}
        step="1000"
        onChange={handleChange}
      />
      <div className="calculator__slider-labels">
        <div>{formatCurrency(minLoanAmount, currencyType, 0)}</div>
        <div>{formatCurrency(maxLoanAmount, currencyType, 0)}</div>
      </div>
    </div>
  );
}

NumericRange.propTypes = {
  currencyType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  loanAmount: PropTypes.number.isRequired,
  maxLoanAmount: PropTypes.number.isRequired,
  minLoanAmount: PropTypes.number.isRequired,
};

export default NumericRange;
