import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NumberFormat from 'react-number-format';

function NumericInput({
  currencyType,
  handleChange,
  loanAmount,
  maxLoanAmount,
  minLoanAmount,
  showWarning,
}) {
  const thousandSeparator = currencyType === '€' ? '.' : ',';
  const decimalSeparator = currencyType === '€' ? ',' : '.';

  return (
    <NumberFormat
      type="tel"
      name="loan-amount-input"
      className={classNames('calculator__numeric-input', {
        'u-mb-x-large': !showWarning,
      })}
      id="loan-amount-input"
      min={minLoanAmount}
      max={maxLoanAmount}
      onValueChange={handleChange}
      value={loanAmount}
      prefix={currencyType}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowNegative={false}
    />
  );
}

NumericInput.propTypes = {
  currencyType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  loanAmount: PropTypes.number.isRequired,
  maxLoanAmount: PropTypes.number.isRequired,
  minLoanAmount: PropTypes.number.isRequired,
  showWarning: PropTypes.bool.isRequired,
};

export default NumericInput;
