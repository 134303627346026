/**
 * section: M2DM/components/scroll-to
 * title: Scroll to
 *
 * jsModule: scroll-to
 * jsFunction: scrollTo
 *
 * purpose: Use to allow a smooth scroll to a target element when clicking a link.
 *
 * description: |
 *   Javascript only component. The clickable element should have `[data-js-scrollto]` as an attribute. The page will scroll to the ID specified as value for `href`. If the target element doesn't exist, the anchor will have an `is-hidden` class on it. If JS is disabled or doesn't work as expected, the browser will do a jump to the target instead of a smooth scroll.<br><br>
 *   If the `[data-js-scrollto]` is added on an element that is not an anchor, then the ID of the target has to be set as a value of the `[data-js-scrollto]` attribute. This has no JS fallback.
 *
 * markup: components/scroll-to/example
 */

import { polyfill } from 'smoothscroll-polyfill';

polyfill();

export default () => {
  const ctas = Array.from(document.querySelectorAll('[data-js-scrollto]'));

  const CONFIG = {
    isHidden: 'is-hidden',
  };

  if (!ctas.length) {
    return;
  }

  ctas.forEach((cta) => {
    const target = cta.dataset.jsScrollto || cta.getAttribute('href');
    const targetEl = document.querySelector(target);

    if (!targetEl) {
      cta.classList.add(CONFIG.isHidden);

      return;
    }

    cta.addEventListener('click', (e) => {
      if (cta.tagName === 'A') {
        e.preventDefault();
      }

      targetEl.scrollIntoView({ block: 'start', behavior: 'smooth' });
    });
  });
};
