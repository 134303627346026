import clamp from 'lodash/clamp';

const COMPONENT_SELECTOR = '[data-tabs]';
const TAB_SELECTOR = '[data-tablist-item]';
const PANEL_SELECTOR = '[data-tabpanel]';
const TABLIST_SELECTOR = '[data-tablist]';

const ARIA_HIDDEN = 'aria-hidden';
const ARIA_SELECTED = 'aria-selected';
const IS_HIDDEN = 'is-hidden';
const IS_SELECTED = 'is-selected';
const JS_PRESENT = 'has-js';

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const TAB = 9;
const SHIFT = 'Shift';

export default function() {
  const components = document.querySelectorAll(COMPONENT_SELECTOR);
  Array.from(components).forEach(createTabComponent);
}

function createTabComponent(element) {
  const component = element;
  const tabs = Array.from(component.querySelectorAll(TAB_SELECTOR));
  const panels = Array.from(component.querySelectorAll(PANEL_SELECTOR));
  const tablist = component.querySelector(TABLIST_SELECTOR);

  showPanel(panels, panels[0]);
  selectTab(tabs, tabs[0]);
  addDefaultAria({ tablist, tabs, panels });

  panels.forEach(setAttribute('tabindex', 0));

  tablist.addEventListener('click', (e) =>
    onTabClicked({ component, tabs, panels }, e)
  );
  tablist.addEventListener('keydown', (e) =>
    onKeyDown({ component, tabs, panels }, e)
  );
  component.classList.add(JS_PRESENT);
}

function onTabClicked({ component, panels, tabs }, event) {
  const selectedTab = event.target;
  if (!selectedTab.matches(TAB_SELECTOR)) {
    return;
  }
  event.preventDefault();

  const selectedPanel = getPanelForTab(component, selectedTab);

  selectTab(tabs, selectedTab);
  showPanel(panels, selectedPanel);
}

function onKeyDown({ component, tabs, panels }, event) {
  const keyPressed = event.keyCode;

  if (!shouldHandleKeyEvent(event)) {
    return;
  }
  event.preventDefault();

  const currentTab = component.querySelector(`.${IS_SELECTED}`);
  let tabIndex = tabs.indexOf(currentTab);

  if (keyPressed === LEFT_ARROW) {
    tabIndex -= 1;
  } else if (keyPressed === RIGHT_ARROW) {
    tabIndex += 1;
  }
  tabIndex = clamp(tabIndex, 0, tabs.length - 1);

  const selectedTab = tabs[tabIndex];
  const selectedPanel = getPanelForTab(component, selectedTab);

  if (keyPressed === TAB) {
    selectedPanel.focus();
  } else {
    selectTab(tabs, selectedTab);
    showPanel(panels, selectedPanel);
  }
}

function shouldHandleKeyEvent(event) {
  return (
    [LEFT_ARROW, RIGHT_ARROW, TAB].includes(event.keyCode) &&
    !event.getModifierState(SHIFT)
  );
}

function showPanel(panels, selectedPanel) {
  panels.forEach((panel) => {
    panel.classList.add(IS_HIDDEN);
    panel.setAttribute(ARIA_HIDDEN, true);
  });

  selectedPanel.classList.remove(IS_HIDDEN);
  selectedPanel.removeAttribute(ARIA_HIDDEN);
}

function selectTab(tabs, selectedTab) {
  tabs.forEach((tab) => {
    tab.classList.remove(IS_SELECTED);
    tab.removeAttribute(ARIA_SELECTED);
    tab.setAttribute('tabindex', -1);
  });

  selectedTab.focus();
  selectedTab.classList.add(IS_SELECTED);
  selectedTab.setAttribute(ARIA_SELECTED, true);
  selectedTab.setAttribute('tabindex', 0);
}

function getPanelForTab(component, selectedTab) {
  const selectedPanelId = selectedTab.getAttribute('href');
  return component.querySelector(selectedPanelId);
}

function addDefaultAria({ tablist, tabs, panels }) {
  tablist.setAttribute('role', 'tablist');
  tabs.forEach((tab) => {
    const id = tab.getAttribute('href').substring(1);
    tab.setAttribute('aria-controls', id);
    tab.setAttribute('role', 'tab');
  });
  panels.forEach(setAttribute('role', 'tabpanel'));
  Array.from(tablist.children).forEach(setAttribute('role', 'presentation'));
}

function setAttribute(attribute, value) {
  return (el) => el.setAttribute(attribute, value);
}
