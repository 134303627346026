import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../icon/react';

const FormElement = ({
  label,
  children,
  status,
  success,
  error,
  warning,
  id,
  extraClasses,
}) => {
  const formClasses = classNames('form-element', extraClasses, {
    'is-success': success,
    'is-error': error,
  });

  return (
    <div className={formClasses}>
      {label && (
        <label
          id={`${id}-label`}
          htmlFor={id}
          className="form-label form-element__label"
        >
          {label}
          <Icon
            name="cross-circle-solid"
            hidden={true}
            modifiers={['solid-error']}
            extraClasses="form-label__icon-error"
          />

          <Icon
            name="tick-circle-solid"
            hidden={true}
            modifiers={['solid-success']}
            extraClasses="form-label__icon-success"
          />
        </label>
      )}

      <div className="form-element__elements">{children}</div>

      {status && (
        <div className="form-field-status">
          <Icon
            name="info-circle-outline"
            hidden={true}
            extraClasses="form-field-status__icon"
          />
          <span className="form-field-status__copy">{status}</span>
        </div>
      )}

      {warning && (
        <div className="form-field-status">
          <Icon
            name="info-circle-solid"
            hidden={true}
            extraClasses="form-field-status__icon"
            modifiers={['solid-caution']}
          />
          <span className="form-field-status__copy">{warning}</span>
        </div>
      )}
    </div>
  );
};

FormElement.propTypes = {
  label: PropTypes.node,
  id: PropTypes.string,
  status: PropTypes.node,
  success: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.node,
  extraClasses: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FormElement;
