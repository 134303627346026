export default (options = {}, defaults) => {
  const config = Object.assign({}, defaults, options);

  // Strip all non-numbers from loan amount
  function stripAllNonNumberCharacters(loanAmount) {
    const strippedString =
      typeof loanAmount === 'string'
        ? loanAmount.replace(/[^\d]/g, '')
        : loanAmount;
    return strippedString;
  }

  // If value is a string coerce to a number
  function coerceStringToNumber(loanAmount) {
    const numericString =
      typeof loanAmount === 'string' ? Number(loanAmount) : loanAmount;
    return numericString;
  }

  config.loanAmount = coerceStringToNumber(
    stripAllNonNumberCharacters(config.loanAmount)
  );
  config.minLoanAmount = coerceStringToNumber(
    stripAllNonNumberCharacters(config.minLoanAmount)
  );
  config.maxLoanAmount = coerceStringToNumber(
    stripAllNonNumberCharacters(config.maxLoanAmount)
  );
  config.selectedLoanTerm = coerceStringToNumber(
    stripAllNonNumberCharacters(config.selectedLoanTerm)
  );
  config.loanTerms = Object.keys(config.ratesPerTerm).map((term) =>
    Number(term)
  );

  return config;
};
