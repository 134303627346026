/**
 * section: M2DM/components/step-card/step-card-list
 * title: Step card list
 *
 * purpose: Use to add the ability to progress through the step cards in a list-like layout.
 *
 * description: |
 *   When loading the page all steps in a list will become inactive except for the first step.
 *
 *   When progressing through steps they will become active as you use the active continue element.
 *
 *   ### Data attributes:
 *     - `[data-js-step-card-list]`: This attribute should be applied as the container of the step cards.
 *     - `[data-js-step-card-continue]`: This attribute should be applied to the element you wish to progress you along in the steps. This is most commonly a button or a radio button/checkbox.
 *
 * markup: components/step-card/js/example/stepCardList
 */

export default () => {
  const CLASS_INACTIVE = 'is-inactive';
  const cardLists = Array.from(
    document.querySelectorAll('[data-js-step-card-list]')
  );

  cardLists.forEach((list) => {
    const cards = Array.from(list.querySelectorAll('[data-js-step-card]'));

    cards.forEach((card, index) => {
      const nextCard = cards[index + 1];
      const continueAction = card.querySelector('[data-js-step-card-continue]');

      if (index > 0) {
        card.classList.add(CLASS_INACTIVE);
      }

      if (typeof nextCard !== 'undefined') {
        continueAction.addEventListener('click', () => {
          nextCard.classList.remove('is-inactive');
        });
      }
    });
  });
};
