/**
 * section: M2DM/components/card-ticker/javascript
 * title: Javascript
 *
 * description: |
 *   JavaScript is required for this component to request the card contents and to cycle through each card.<br><br>
 *
 *   The contents of the cards are generated dynamically from a text file that is fetched across the network.<br><br>
 *
 *   To create a connection between a particular card-ticker component and its source of data, `data-js-card-ticker-source="path-to-source-file"` needs to be present at the component level. This data-attribute will hold the path to the source file that populates the cards with content.<br><br>
 *
 *   If the request for data is unsuccessful, the component will not be displayed on the page.
 *
 *   ### Data attributes:
 *   - `[data-js-card-ticker]`: This attribute identifies the wrapper that holds the card-ticker carousel;
 *   - `[data-js-card-ticker-carousel]`: This attribute identifies the card-ticker carousel;
 *   - `[data-js-card-ticker-item]`: This attribute identifies an item within the card-ticker carousel;
 *   - `[data-js-card-ticker-source]`: This attribute creates a connection between the component and its source of data. It holds the path to the source file that populates the cards with content
 *
 * layout: components/card-ticker/example
 */

export default () => {
  const cardTicker = document.querySelector('[data-js-card-ticker]');

  if (!cardTicker) {
    return;
  }

  const cardTickerCarousel = cardTicker.querySelector(
    '[data-js-card-ticker-carousel]'
  );
  const url = cardTicker.dataset.jsCardTickerSource;

  const removeComponent = (component) => {
    const componentParent = component.parentNode;

    componentParent.removeChild(component);
  };

  const cardTickerItem = (fetchedData) => `
    <div class="card-ticker__item" data-js-card-ticker-item>
      <p class="card-ticker__item-content">${fetchedData}</p>
    </div>
  `;

  fetch(url)
    .then((response) => {
      if (response.ok) {
        return response.text();
      }

      return Promise.reject();
    })
    .then((cardTickerData) => {
      const lines = cardTickerData
        .split('\n')
        .filter((line) => line.length > 0);

      if (!lines.length) {
        removeComponent(cardTicker);
        return;
      }

      lines.forEach((line) => {
        const regex = /\*(.+?)\*/g;

        const formattedLine = line
          .replace(
            regex,
            (match, textToBold) => `<strong>${textToBold}</strong>`
          )
          .trim();

        cardTickerCarousel.innerHTML += cardTickerItem(formattedLine);
      });

      cardTickerCarousel.addEventListener('animationiteration', () => {
        const [firstCardItem] = cardTickerCarousel.querySelectorAll(
          '[data-js-card-ticker-item]'
        );

        cardTickerCarousel.appendChild(firstCardItem);
      });
    })
    .catch(() => {
      removeComponent(cardTicker);
    });
};
