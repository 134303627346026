/* eslint-disable max-len, no-plusplus, array-callback-return */
export default () => {
  const isLocalDataExpired = (data, lastUpdated) => {
    if (!data || !lastUpdated) return false;

    const dataExpirationTime = 1000 * 60 * 60 * 24 * 1; // refresh every 1 days
    const dataExpired = Date.now() > lastUpdated + dataExpirationTime;

    return dataExpired;
  };

  const localStorageSupported = () => {
    const mod = 'modernizr';
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getLocalData = () => {
    const localData = JSON.parse(localStorage.getItem('trustpilot_api_data'));
    const localDataLastUpdated = Number(
      localStorage.getItem('trustpilot_api_data_last_updated')
    );
    const localDataExpired = isLocalDataExpired(
      localData,
      localDataLastUpdated
    );

    return {
      localData,
      localDataExpired,
    };
  };

  const hasLocalCachedData = () => {
    if (!localStorageSupported()) {
      return false;
    }

    const { localData, localDataExpired } = getLocalData();
    return localData && !localDataExpired;
  };

  const setLocalData = (data) => {
    if (localStorageSupported()) {
      localStorage.setItem('trustpilot_api_data', JSON.stringify(data));
      localStorage.setItem('trustpilot_api_data_last_updated', Date.now());
    }

    return data;
  };

  const removeAllComponents = (components) => {
    components.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item.parentNode?.removeChild(item);
    });
  };

  const fetchTrustPilotData = () => {
    const dataPath =
      'https://api.trustpilot.com/v1/business-units/4fa92f2600006400051522e5';
    const tpApiKey = 'y0WQnjukauzqs4qQGiql8VZRGtFmaOtn';
    const url = `${dataPath}?apikey=${tpApiKey}`;

    return fetch(url).then((response) => {
      if (!response.ok) throw response;
      return response.json();
    });
  };

  const renderTrustPilotComponents = (data, components) => {
    // remove all components if the stars count has dropped to 3 or below or if data.score reference does not exist
    if (!data?.score || data.score.stars <= 3) {
      removeAllComponents(components);
      return;
    }

    components.forEach((item) => {
      const stars = Array.from(
        item.querySelectorAll('[data-js-trustpilot-stars]')
      );
      const reviewCount = Array.from(
        item.querySelectorAll('[data-js-trustpilot-review-count]')
      );
      const trustscore = Array.from(
        item.querySelectorAll('[data-js-trustpilot-trustscore]')
      );
      const headlineRating = Array.from(
        item.querySelectorAll('[data-js-trustpilot-headline-rating]')
      );

      stars.forEach((el) => {
        el.innerHTML = '';

        const scoreFloor = Math.floor(data.score.stars);

        for (let i = 0; i < scoreFloor; i++) {
          const imgStar = document.createElement('span');
          imgStar.className = 'trustpilot__star trustpilot__star--whole';
          el.appendChild(imgStar);
        }

        if (data.score.stars % 1 !== 0) {
          const imgHalfStar = document.createElement('span');
          imgHalfStar.className = 'trustpilot__star trustpilot__star--half';
          el.appendChild(imgHalfStar);
        }
      });

      reviewCount.forEach((el) => {
        el.textContent = data.numberOfReviews.total;
      });
      trustscore.forEach((el) => {
        el.textContent = data.score.trustScore;
      });
      headlineRating.forEach((el) => {
        const text5stars = el.getAttribute('data-content-5stars');
        const text4stars = el.getAttribute('data-content-4stars');

        if (data.score.stars === 5 || data.score.stars === 4.5) {
          el.textContent = text5stars;
        } else if (data.score.stars === 4) {
          el.textContent = text4stars;
        }
      });

      item.classList.add('is-visible');
    });
  };

  const start = () => {
    const components = Array.from(
      document.querySelectorAll('[data-js-trustpilot-api]')
    );
    // if there's no trustpilot components, abort here
    if (!components.length) return;

    if (hasLocalCachedData()) {
      const { localData } = getLocalData();
      renderTrustPilotComponents(localData, components);
    } else {
      fetchTrustPilotData()
        .then(setLocalData)
        .then((data) => renderTrustPilotComponents(data, components))
        .catch(() => {
          removeAllComponents(components);
        });
    }
  };

  return {
    getLocalData,
    setLocalData,
    isLocalDataExpired,
    fetchTrustPilotData,
    renderTrustPilotComponents,
    start,
  };
};
