const MONTHS_IN_YEAR = 12;

function calculateAmortisedValue(loanAmount, loanTerm, rates) {
  const totalLoanAmount = parseInt(loanAmount, 10);
  const { completionRate, interestRate } = rates[loanTerm];

  const completionFee = totalLoanAmount * completionRate;
  const loanWithFees = totalLoanAmount + completionFee;
  const monthlyInterestRate = interestRate / MONTHS_IN_YEAR;

  const denominator = (1 + monthlyInterestRate) ** loanTerm - 1;

  const monthlyPayment =
    loanWithFees * (monthlyInterestRate / denominator + monthlyInterestRate);
  const totalInterest = monthlyPayment * loanTerm - loanWithFees;
  const totalCost = loanWithFees + totalInterest;

  return {
    monthlyPayments: Math.round(monthlyPayment),
    totalCost: Math.round(totalCost),
    completionFee: Math.round(completionFee),
    interestFee: Math.round(totalInterest),
  };
}

export default calculateAmortisedValue;
