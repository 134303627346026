import transitionSupported from '../../../globals/js/transitions-supported';

export default () => {
  const VIDEO_BLOCK_DATA_ATTR = 'data-js-video-block';
  const VIDEO_BLOCK_DATA_ATTR_JS = 'jsVideoBlock';
  const VIDEO_BLOCK_TRIGGER_DATA_ATTR = 'data-js-video-block-trigger';
  const VIDEO_BLOCK_CONTAINER_DATA_ATTR = 'data-js-video-block-container';
  const VIDEO_BLOCK_PLAYER_DATA_ATTR = 'data-js-video-block-player';
  const VISIBLE_CLASS = 'is-visible';
  const TRANSITIONING_CLASS = 'is-transitioning';
  const RATIO = 16 / 9;
  const TRANSITION_SUPPORTED = transitionSupported;
  const videos = Array.from(
    document.querySelectorAll(`[${VIDEO_BLOCK_DATA_ATTR}]`)
  );

  videos.forEach((video) => {
    if (!getVideoId(video)) {
      return;
    }

    init(video);
    bindEvents(video);
    updatePlayerSize(video);
  });

  function init(video) {
    const container = getContainer(video);

    container.appendChild(video);

    if (getComputedStyle(container).position === 'static') {
      container.style.position = 'relative';
    }
  }

  function bindEvents(video) {
    const selector = `[${VIDEO_BLOCK_TRIGGER_DATA_ATTR}="${getVideoId(
      video
    )}"]`;
    const ctas = Array.from(document.querySelectorAll(selector));

    ctas.forEach((cta) => {
      cta.addEventListener('click', () => {
        toggle(video);
      });
    });

    window.addEventListener('resize', () => {
      window.requestAnimationFrame(() => {
        updatePlayerSize(video);
      });
    });

    video.addEventListener('transitionend', () => {
      video.classList.remove(TRANSITIONING_CLASS);
    });
  }

  function toggle(video) {
    if (TRANSITION_SUPPORTED) {
      video.classList.add(TRANSITIONING_CLASS);
    }

    // Force a repaint after the TRANSITIONING_CLASS was added
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        video.classList.toggle(VISIBLE_CLASS);
        togglePlayer(video, video.classList.contains(VISIBLE_CLASS));
      });
    });

    updatePlayerSize(video, { force: true });
  }

  function getVideoId(video) {
    return video.dataset[VIDEO_BLOCK_DATA_ATTR_JS];
  }

  function getContainer(video) {
    return document.querySelector(
      `[${VIDEO_BLOCK_CONTAINER_DATA_ATTR}="${getVideoId(video)}"]`
    );
  }

  function togglePlayer(video, state) {
    const player = video.querySelector(`[${VIDEO_BLOCK_PLAYER_DATA_ATTR}]`);
    const functionName = state ? 'play' : 'stop';
    const data = {
      event: 'command',
      func: `${functionName}Video`,
    };

    if (!player.contentWindow) return;

    player.contentWindow.postMessage(JSON.stringify(data), '*');
  }

  function updatePlayerSize(video, options = {}) {
    const container = getContainer(video);
    const player = video.querySelector(`[${VIDEO_BLOCK_PLAYER_DATA_ATTR}]`);
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    let width;
    let height;

    if (!options.force && !video.classList.contains(VISIBLE_CLASS)) {
      return;
    }

    if (containerWidth / containerHeight > RATIO) {
      height = containerHeight;
      width = height * RATIO;
    } else {
      width = containerWidth;
      height = width / RATIO;
    }

    player.style.width = `${width}px`;
    player.style.height = `${height}px`;
  }
};
