import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../icon/react';
import FormElement from './form-element';

const Select = ({
  error,
  label,
  listeners,
  name,
  onBlur,
  onChange,
  options,
  status,
  success,
  value,
  disabled,
  extraClasses,
}) => {
  const id = name.replace(/[\W]/g, '-');

  return (
    <FormElement
      id={id}
      label={label}
      status={status}
      success={success}
      error={error}
      extraClasses={extraClasses}
    >
      <div
        className={classNames('form-select form-input--icon', {
          'is-disabled': disabled,
        })}
      >
        <select
          className="form-select__element"
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          {...listeners}
        >
          {options.map((option, i) => (
            <option key={i} value={option.value} disabled={!!option.disabled}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon name="arrowhead-down" extraClasses="form-select__icon" />
      </div>
    </FormElement>
  );
};

Select.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  listeners: PropTypes.objectOf(PropTypes.func),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }).isRequired
  ).isRequired,
  status: PropTypes.node,
  success: PropTypes.bool,
  value: PropTypes.string,
  extraClasses: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};

export default Select;
