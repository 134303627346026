import { formatMoney } from 'accounting';

function formatCurrency(value, currencyType, decimalPlaces = 2) {
  let thousandSeparator;
  let centSeparator;

  switch (currencyType) {
    case '£':
    case '$':
      thousandSeparator = ',';
      centSeparator = '.';
      break;
    case '€':
      thousandSeparator = '.';
      centSeparator = ',';
      break;
    default:
      thousandSeparator = ',';
      centSeparator = '.';
  }

  const formattedMoney = formatMoney(
    value,
    currencyType,
    decimalPlaces,
    thousandSeparator,
    centSeparator
  );

  return formattedMoney;
}

export default formatCurrency;
