/**
 * section: M2DM/components/step-card/javascript
 * title: Javascript
 *
 * purpose: Use to add transition between collapsed and open cards.
 *
 * description: |
 *   ### Data attributes:
 *     - `[data-js-step-card]`: This is the selector which is looped through to initiate the component via Javascript.
 *     - `[data-js-step-card-toggle]`: This element in which toggles between the collapsed state of the card on click.
 *     - `[data-js-step-card-toshow]` (optional when toggling, no animation if not applied): This element is faded out when transitioning between collapsed states, this is swapped with the `[data-js-step-card-tohide]` when toggling.
 *     - `[data-js-step-card-tohide]` (optional when toggling, no animation if not applied): This element is faded in when transitioning between collapsed states, this is swapped with the `[data-js-step-card-toshow]` when toggling.
 *
 *   ### Other options:
 *     - `toCollapse`: If you attach `toCollapse` as a function on your step DOM element, this will allow you to control when the collapse happens by using the callback provided by the function e.g.:
 *       ```
 *          document.querySelector([step selector]).toCollapse = (collapseCard) => {
 *            //do stuff before collapse
 *            collapseCard();
 *          }
 *       ```
 *
 * markup: components/step-card/example
 */

export default () => {
  const CLASS_COLLAPSED = 'is-collapsed';
  const TRANSITION_SUPPORTED =
    'transition' in document.documentElement.style ||
    'MozTransition' in document.documentElement.style ||
    'OTransition' in document.documentElement.style ||
    'WebkitTransition' in document.documentElement.style;

  const steps = Array.from(document.querySelectorAll('[data-js-step-card]'));

  function whenAnimationFrame() {
    return new Promise((resolve) => {
      requestAnimationFrame(resolve);
    });
  }

  steps.forEach((step) => {
    const toggles = Array.from(
      step.querySelectorAll('[data-js-step-card-toggle]')
    );
    const collapse = step.querySelector('[data-js-step-card-tohide]');
    const content = step.querySelector('[data-js-step-card-toshow]');

    const handleCollapse = () => {
      if (!content || !collapse) {
        step.classList.toggle(CLASS_COLLAPSED);

        return;
      }

      const initialHeight = step.offsetHeight;
      let transitionToHeight;
      let toHide = collapse;
      let toShow = content;

      function resetStyles() {
        toHide.style.display = '';
        toHide.style.opacity = '';
        toShow.style.display = '';
        toShow.style.opacity = '';
        step.style.height = '';
      }

      resetStyles();

      if (step.classList.contains(CLASS_COLLAPSED)) {
        [toShow, toHide] = [toHide, toShow];
      }

      requestAnimationFrame(() => {
        step.classList.toggle(CLASS_COLLAPSED);
        transitionToHeight = step.offsetHeight;

        step.classList.toggle(CLASS_COLLAPSED);
        step.style.height = `${initialHeight}px`;

        whenAnimationFrame()
          .then(
            () =>
              new Promise((resolve) => {
                if (!TRANSITION_SUPPORTED) {
                  resolve();
                  return;
                }

                const handleToHideTranition = (e) => {
                  e.stopPropagation();
                  toHide.removeEventListener(
                    'transitionend',
                    handleToHideTranition
                  );

                  toHide.style.display = 'none';

                  resolve();
                };
                toHide.addEventListener('transitionend', handleToHideTranition);

                step.style.height = `${transitionToHeight}px`;
                toHide.style.opacity = 0;
              })
          )
          .then(
            () =>
              new Promise((resolve) => {
                if (!TRANSITION_SUPPORTED) {
                  resolve();
                  return;
                }

                const handleToShowTransition = (e) => {
                  e.stopPropagation();
                  toShow.removeEventListener(
                    'transitionend',
                    handleToShowTransition
                  );

                  resolve();
                };
                toShow.addEventListener(
                  'transitionend',
                  handleToShowTransition
                );

                whenAnimationFrame()
                  .then(() => {
                    toShow.style.display = 'block';

                    return whenAnimationFrame();
                  })
                  .then(() => {
                    toShow.style.opacity = 1;
                  });
              })
          )
          .then(() => {
            step.classList.toggle(CLASS_COLLAPSED);
          })
          .then(resetStyles)
          .then(() => {
            step.dispatchEvent(new Event('doneAnimation'));
          });
      });
    };

    toggles.forEach((toggle) => {
      toggle.addEventListener('click', () => {
        if (
          typeof step.toCollapse === 'function' &&
          !step.classList.contains(CLASS_COLLAPSED)
        ) {
          step.toCollapse(handleCollapse);
        } else {
          handleCollapse();
        }
      });
    });
  });
};
