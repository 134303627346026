export default () => {
  const progressPercentage = document.querySelector(
    '[data-js-progress-percentage]'
  );
  const progressContainers = document.querySelectorAll('.progress');

  if (progressPercentage) {
    progressPercentage.addEventListener('keyup', () => {
      const isComplete = parseInt(progressPercentage.value, 10) >= 100;

      progressContainers.forEach((progressContainer) => {
        progressContainer.classList.toggle('is-complete', isComplete);
        const progressActivity = progressContainer.querySelector(
          '.progress__activity'
        );

        progressActivity.style.width = `${progressPercentage.value}%`;
      });
    });
  }
};
