import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Donut from './Donut';
import Select from '../../../forms/react/select';
import LoanBreakDown from './Loan-Breakdown';
import NumericInput from './Numeric-Input';
import NumericRange from './Numeric-Range';

class Calculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: true,
      showWarning: false,
      ...this.props.initialState,
    };
  }

  handleNumericInputChange = (e) => {
    const { maxLoanAmount, minLoanAmount } = this.state;
    const loanAmount = Math.min(+e.value, maxLoanAmount);
    this.setState({
      loanAmount,
      showWarning: loanAmount < minLoanAmount,
    });
  };

  handleRangeChange = (e) => {
    this.setState({ loanAmount: +e.target.value });
  };

  handleTermChange = (value) => {
    this.setState({ selectedLoanTerm: Number(value) });
  };

  showHideBreakDown = () => {
    this.setState((prevState) => ({ isHidden: !prevState.isHidden }));
  };

  render() {
    const {
      calculatorBlurb,
      cta,
      currencyType,
      isHidden,
      loanAmount,
      loanAmountLabel,
      loanBreakDownLabels,
      maxLoanAmount,
      minLoanAmount,
      minAmountLabelWarning,
      monthlyRepaymentLabel,
      ratesPerTerm,
      selectedLoanTerm,
      showWarning,
      termLengthLabel,
      termLengthYear,
    } = this.state;

    const loanTerms = Object.keys(ratesPerTerm).map((term) => {
      return { value: String(term), label: `${term} months` };
    });

    return (
      <section className="calculator">
        <div className="calculator__loan-options">
          <p className="u-mb-x-small calculator__loan-amount-label">
            {loanAmountLabel}
          </p>
          <div
            className={classNames('u-mb-medium', 'calculator__inputs', {
              'is-error': showWarning,
            })}
          >
            <NumericInput
              currencyType={currencyType}
              handleChange={this.handleNumericInputChange}
              loanAmount={loanAmount}
              maxLoanAmount={maxLoanAmount}
              minLoanAmount={minLoanAmount}
              showWarning={showWarning}
            />
            <div
              className={classNames('calculator__input-warning', {
                'is-error u-mb-small': showWarning,
              })}
            >
              <div className="form-field-status">
                <svg
                  className="svg-icon form-field-status__icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="https://dgnvhpcjbd3ba.cloudfront.net/radius/10.15.1/icons/svg-sprite.svg#info-circle-outline"></use>
                </svg>
                <span className="form-field-status__copy">
                  {minAmountLabelWarning}
                </span>
              </div>
            </div>
            <NumericRange
              currencyType={currencyType}
              handleChange={this.handleRangeChange}
              loanAmount={loanAmount}
              maxLoanAmount={maxLoanAmount}
              minLoanAmount={minLoanAmount}
            />
          </div>

          <p className="u-mb-x-small calculator__loan-amount-label">
            {termLengthLabel}
          </p>

          <div tabIndex={0}>
            <Select
              options={loanTerms}
              onChange={(event) =>
                this.handleTermChange(event.currentTarget.value)
              }
              name=""
            ></Select>
          </div>

          <div className="cta-combo calculator__button--mobile u-mb-medium">
            <a
              className="cta-primary cta-combo__primary"
              href={cta.primaryCtaLink}
            >
              {cta.primaryCtaLabel}
            </a>
            <span className="cta-combo__secondary-wrap">
              {cta.connectorLabel}
              <a
                className="cta-link cta-combo__secondary"
                href={cta.secondaryCtaLink}
              >
                {cta.secondaryCtaLabel}
              </a>
            </span>
          </div>
          <p
            className="u-mb-none calculator__blurb"
            dangerouslySetInnerHTML={{ __html: calculatorBlurb }}
          ></p>
        </div>
        <div className="calculator__outputs">
          <Donut
            currencyType={currencyType}
            loanAmount={loanAmount}
            loanTerm={selectedLoanTerm}
            rates={ratesPerTerm}
            monthlyRepaymentLabel={monthlyRepaymentLabel}
          />
          <LoanBreakDown
            cta={cta}
            currencyType={currencyType}
            isHidden={isHidden}
            labels={loanBreakDownLabels}
            loanAmount={loanAmount}
            loanTerm={selectedLoanTerm}
            rates={ratesPerTerm}
            showHide={this.showHideBreakDown}
            termLengthYear={termLengthYear}
          />
        </div>
      </section>
    );
  }
}

Calculator.propTypes = {
  initialState: PropTypes.shape({
    calculatorBlurb: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      primaryCtaLabel: PropTypes.string.isRequired,
      primaryCtaLink: PropTypes.string.isRequired,
      primaryCustomClass: PropTypes.string.isRequired,
      connectorLabel: PropTypes.string.isRequired,
      secondaryCtaLabel: PropTypes.string.isRequired,
      secondaryCtaLink: PropTypes.string.isRequired,
      secondaryCustomClass: PropTypes.string.isRequired,
    }).isRequired,
    currencyType: PropTypes.string.isRequired,
    loanAmount: PropTypes.number.isRequired,
    loanAmountLabel: PropTypes.string.isRequired,
    loanBreakDownLabels: PropTypes.shape({
      hideLoanBreakdownLabel: PropTypes.string.isRequired,
      interestLabel: PropTypes.string.isRequired,
      loanAmountLabel: PropTypes.string.isRequired,
      originationFeeLabel: PropTypes.string.isRequired,
      showLoanBreakdownLabel: PropTypes.string.isRequired,
      totalCostLabel: PropTypes.string.isRequired,
    }).isRequired,
    maxLoanAmount: PropTypes.number.isRequired,
    minAmountLabelWarning: PropTypes.string.isRequired,
    minLoanAmount: PropTypes.number.isRequired,
    monthlyRepaymentLabel: PropTypes.string.isRequired,
    ratesPerTerm: PropTypes.objectOf(
      PropTypes.shape({
        interestRate: PropTypes.number.isRequired,
        completionRate: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    selectedLoanTerm: PropTypes.number.isRequired,
    termLengthLabel: PropTypes.string.isRequired,
    termLengthYear: PropTypes.string.isRequired,
  }),
};

export default Calculator;
