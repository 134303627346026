// Taken from clipboard.js" by Zeno Rocha is licensed under MIT: https://github.com/zenorocha/clipboard.js
export default function ClipboardCopier(data) {
  const createFakeTextarea = () => {
    const yPosition = window.pageYOffset || document.documentElement.scrollTop;
    const fakeTextArea = document.createElement('textarea');

    fakeTextArea.style.padding = '0';
    fakeTextArea.style.margin = '0';
    fakeTextArea.style.border = '0';
    fakeTextArea.style.position = 'absolute';
    fakeTextArea.style.fontSize = '12pt';
    fakeTextArea.style.left = '-9999px';
    fakeTextArea.style.top = `${yPosition}px`;
    fakeTextArea.setAttribute('readonly', '');

    return fakeTextArea;
  };

  const htmlDecode = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  const removeElement = (el) => {
    if ('remove' in el) {
      el.remove();
    } else if (el.parentNode) {
      el.parentNode.removeChild(el);
    }
  };

  const copy = () => {
    const fakeTextArea = createFakeTextarea();
    const htmlDecodedCode = htmlDecode(data);

    fakeTextArea.value = htmlDecodedCode;
    document.body.appendChild(fakeTextArea);
    fakeTextArea.select();
    document.execCommand('copy');

    removeElement(fakeTextArea);

    return htmlDecodedCode;
  };

  return {
    copy,
  };
}
